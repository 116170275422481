import { Pipe, PipeTransform } from "@angular/core";
import { APP_DATE_DISPLAY_FORMATS } from "app/helpers/app-constants";
import { DateTime } from "luxon";

@Pipe({
    name: 'dateTimeFormat',
    standalone: true,
})
export class CustomDatePipe implements PipeTransform {
    transform(value: any, format: string = APP_DATE_DISPLAY_FORMATS.fullDateTime): string {
        if (!value) return '';

        // Parse the input as a Luxon DateTime object
        const dt = DateTime.fromISO(value);  // You can change this based on your input format

        if (!dt.isValid) return '';

        return dt.toFormat(format);
    }
}